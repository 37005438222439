<script>
// import taskWindow from '../windows/tarefa'
import TaskWindowView from "@/components/sltarefas/components/Tarefa"

export default {
  name: "OpenTask",
  components: {TaskWindowView},
  created() {
    //this.taskWindow(this.$route.params.id)
  },
  methods: {
    // bemWindow: bemWindow
  }
}
</script>

<template>
  <task-window-view class="window-task task-from-page bg-white" style="margin: auto" :id="$route.params.id" :$router="$router" />
</template>
